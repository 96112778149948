import React from 'react';
import VideoSection from '../components/VideoSection'
import Spliter from '../components/Splitter'
import PictureSection from '../components/PictureSection';

import '../scss/pages/Home.scss';


export default function Home() {
  return (
    <div id="homepage-content">
      <VideoSection strapline={['Turbocharged Tree', <br/>, 'Growth', 'using cutting edge technology', 'to fight the climate crisis.']} />
      <Spliter gotoId='why'/>
      <PictureSection id='why' title='Why Change?' blurb={[`The climate emergency is one of the biggest threats to the existence of life on our planet. 
      Not only are we effecting our on ways of life but we are also altering, the planet for all life and because we are changing 
      all of our amazing and varied environments in their composition, including temperature and at a chemical level, at such a quick 
      rate though the wonderful life on this planet can adapt it can’t change quick enough for the changes we are forcing upon it.`]} />
      <Spliter gotoId='what'/>
      <PictureSection id='what' title='What can we do?' blurb={[`So, what can we do to help when the problem is on such a massive scale and involves 
      all the complexities of global economies, both emerging and the old establishment, how do we persuade people to make the changes required to stop the decline in the planet’s health?`,
      `We at Watts in the Woods believe that the use of technology, increases in efficiency and reductions to waste couple with green and sustainable power and practices can make massive 
      strides in tackling the global climate crisis.`, `But how do we get people, communities, businesses, councils, and governments to adopt and technology and practices?  
      We believe it is through significant reductions to the barriers of entry to high tech, green and sustainable technologies such as reduction to the massive initial outlay often 
      required to adopting cutting edge sustainable technologies and by using high-tech monitoring and intuitive software a reduction to the time investment required to setup, run, maintain, 
      and monitor complex systems.`,
      `We believe most people are aware and want to make a difference and help fight climate change but many not be willing to do that at the price of their family’s welfare so an attractive 
      rate of investment must also be offered.`]} />
    </div>
  );
}
