import React from 'react';
import '../scss/PictureSection.scss';

function PictureSection(props) {
    const {id, blurb, title } = props;
return (
    <div className='section'>
        <div className="picture-container" id={id}>
            <h1>{title}</h1>
            {blurb.map((content,i) => <p key={i}> {content}</p>) }
        </div>
    </div>
)
}

export default PictureSection;
