import React from "react"
import '../scss/Splitter.scss';

function Splitter(props) {
    const { gotoId } = props;
        return(
            <div className="splitter">
                <div className="page-scroll">
                    <a href={`#${gotoId}`} className="btn btn-circle">
                        <i className="fa fa-angle-down fa-2x animated"></i>
                    </a>
                </div>
            </div>
        );
}

export default Splitter;
