import React from 'react';
import ReactPlayer from 'react-player/lazy'
import '../scss/VideoSection.scss'
import video from '../../public/assets/video/trees-big.mp4'


function VideoSection(props) {
      return (
        <div className='section'>
          <div className="video-container">
            <ReactPlayer
              className='react-player'
              url={[ 
                      {src: video, type: 'video/mp4'},
                  ]}
              playing={true}
              loop={true}
              muted={true} // Needed for autoplay to work on chrome
              width='100%'
              height='100%'
              config={{
                  file: {attributes:{style:{height:"100%", width:"auto"}, autoPlay:1}  }
              }}
            />
          </div>
          <div className='overlay noselect'>
              <h1>{props.strapline[0]} {props.strapline[1]} {props.strapline[2]}</h1>
              <h2>{props.strapline[3]}</h2>
              <h1><span>{props.strapline[4]}</span></h1>
          </div>
        </div>
      )
  }

  export default VideoSection;