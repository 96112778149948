import React, { useEffect }from 'react';
import '../scss/pages/ContactUs.scss';
import '../../node_modules/splitting/dist/splitting.css'
import '../../node_modules/splitting/dist/splitting-cells.css'
import Splitting from '../../node_modules/splitting'
import Meanderer from '../../node_modules/meanderer'


export default function ContactUs() {

  useEffect(() => {
    let element = document.getElementById("email-link");
    Splitting({target: element,
      by: "chars",
      whitespace: true,
      key: null});
    
    const V = 190; // vertical
    const H = 260; // horizontal
    const OS = 20; // offset
    const CR = 'a20 20 0 0 1'; // corner
    
    const CONTAINER = document.querySelector('#email-animation-text');
    const TEXTPATH = `M0,${V} v-${V} ${CR} 20,-20 h${H} ${CR} 20,20 v${V} ${CR} -20, 20 h-${H-OS} ${CR} -20,-20 
      v-${V-OS} ${CR} 20,-20 h${H-2*OS} ${CR} 20 20 v${V-2*OS} ${CR} -20 20 h-${H-3*OS} ${CR} -20 -20 
      v-${V-3*OS} ${CR} 20,-20 h${H-4*OS} ${CR} 20,20 v${V-4*OS} ${CR} -20, 20 h-${H-5*OS} ${CR} -20,-20
      v-${V-5*OS} ${CR} 20,-20 h${H-6*OS} ${CR} 20,20 v${V-6*OS} ${CR} -20, 20 h-${H-7*OS} ${CR} -20,-20
      v-${V-7*OS} ${CR} 20,-20 h${H-8*OS} ${CR} 20,20 v${V-8*OS} ${CR} -20, 20 h-${H-9*OS} ${CR} -20,-20`

    let responsiveSvgPath = new Meanderer({
      path: TEXTPATH,
      width: 300,
      height: 200,
    });

    const setPath = () => {
      const scaledPath = responsiveSvgPath.generatePath(
        CONTAINER.offsetWidth,
        CONTAINER.offsetHeight
      )
      CONTAINER.style.setProperty('--textPath', `"${scaledPath}"`);
    }
    const SizeObserver = new ResizeObserver(setPath);
    SizeObserver.observe(CONTAINER);
  });

  return (
    <div id="contactus-content">
      <div className='section'>
        <div className='animation-container'>
          <div id='email-animation'>
            <div id='email-animation-text'>
              <a href="mailto:admin@wattsinthewoods.co.uk?subject=Enquiry from website" id='email-link'>admin@wattsinthewoods.co.uk    
                <i className="fa fa-envelope"></i>
              </a> 
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
